.button {
  @apply relative uppercase flex justify-center items-center cursor-pointer rounded-md;
  font-family: var(--font-family-secondary);
  min-height: 3.125rem;
  color: var(--text-primary-button);
  background-color: var(--bg-primary-button);
  padding: 0.25rem 2.25rem;
  transition: all 0.3s ease;
  &:hover {
    color: var(--hover-text-primary-button);
    background-color: var(--hover-primary-button);
  }
}

.button-medium {
  @extend .button;
  min-height: 2.5rem;
  padding: 0.25rem 1.75rem;
}

.button-small {
  @extend .button;
  min-height: 2rem;
  padding: 0.25rem 1.25rem;
}

.button-large {
  @extend .button;
  min-height: 3.75rem;
  padding: 0.25rem 3rem;
}
