.footer {
  border-top: 1px solid var(--divider-color);
  .codedBy {
    color: var(--title-article);
    font-weight: 300;
    // .author {
    //   color: #444;
    // }
  }
  .group {
    @apply flex gap-8;
  }
}

@media (max-width: 576px) {
  .footer {
    .codedBy {
      font-size: 14px;
    }
    .group {
      @apply gap-4;
      svg {
        width: 22px;
        height: 22px;
      }
    }
  }
}

[data-theme="dark"] {
  .footer {
    .group {
      svg {
        path {
          fill: var(--paragraph-color);
        }
      }
    }
  }
}
