.header1 {
  height: var(--header-height);

  &.switched {
  }
  &.hidden {
    transform: translateY(-100%);
  }
}
.header {
  transition: all 0.5s ease-in-out;
  background-color: var(--layer-2);
  // border-bottom: 1px solid var(--divider-color);
  .headerMain {
    @apply relative items-center flex w-full;
    height: var(--header-height);
    font-size: var(--text-header);
    .left {
      @apply absolute;
      .logo {
        @apply uppercase flex items-center;
        transition: all 0.5s ease-in-out;
        filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(159deg)
          brightness(101%) contrast(101%);
      }
    }
    .center {
      @apply flex flex-1 justify-center;
      .logo {
        transition: all 0.5s ease-in-out;
        filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(159deg)
          brightness(101%) contrast(101%);
      }
    }
    .right {
      @apply absolute text-right right-0 flex items-center h-full;
      .nav {
        @apply cursor-pointer text-white uppercase;
        font-family: var(--font-family-secondary);
        padding: 0 1rem;
      }

      button {
        width: 40px;
        height: 40px;
        svg {
          path {
            fill: var(--white);
          }
        }
      }
    }
  }
  .desktop {
    @apply flex;
  }
  .mobile {
    display: none;
  }
  &.hidden {
    transform: translateY(-100%);
  }
  &.switched {
    background-color: white;
    .headerMain {
      .left {
        .logo {
          filter: initial;
        }
      }
      .center {
        .logo {
          filter: initial;
        }
      }
      .right {
        .nav {
          @apply text-grey-1;
        }
        .menu {
          span {
            background-color: var(--grey-1);
          }
          path {
            stroke: var(--grey-1);
          }
        }
        button {
          width: 40px;
          height: 40px;
          svg {
            path {
              fill: var(--grey-1);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1280px) {
}
@media (max-width: 1023px) {
}
@media (max-width: 576px) {
  .header {
    .headerMain {
      .left {
        display: none;
      }
      .right {
        @apply w-full flex justify-center;
        .nav {
          display: block;
          margin: 0;
          padding: 1rem;
          font-size: 0.875rem;
        }
      }
    }
    .desktop {
      display: none;
    }
    .mobile {
      &.headerMain {
        .left {
          display: flex;
        }
        .right {
          @apply w-auto gap-2;
        }
      }
      @apply flex;
    }
  }
  .modalMenu {
    @apply w-screen h-screen;
    .wrapImage {
      @apply w-screen h-screen;
      .image {
        @apply w-screen h-screen;
        img {
          opacity: 1;
        }
      }
    }
    .nav {
      @apply cursor-pointer text-white uppercase;
      font-size: 0.875rem;
    }
  }
}
[data-theme="dark"] {
  .header {
    &.switched {
      .headerMain {
        .right {
          button {
            svg {
              path {
                fill: var(--grey-1);
                // stroke: var(--black);
              }
            }
          }
        }
      }
    }
  }
}
