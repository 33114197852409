.header {
  @apply flex items-center justify-end;
  height: var(--header-height);
  .menu {
  }
}
.nav {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(65 66 65 / var(--tw-text-opacity));
  font-family: var(--font-family-secondary);
  font-size: 16px;
  letter-spacing: 0.1em;
  .label {
    @apply text-white uppercase;
    font-family: var(--font-family-secondary);
    padding: 0 1rem;
  }
}
